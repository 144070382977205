"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTradeAmountTooSmallErr = exports.makeSwapErrorRight = void 0;
const types_1 = require("./types");
const makeSwapErrorRight = ({ details, cause, code, message, }) => ({
    name: 'SwapError',
    message,
    details,
    cause,
    code,
});
exports.makeSwapErrorRight = makeSwapErrorRight;
const createTradeAmountTooSmallErr = (details) => (0, exports.makeSwapErrorRight)({
    code: types_1.TradeQuoteError.SellAmountBelowMinimum,
    message: 'Sell amount is too small',
    details,
});
exports.createTradeAmountTooSmallErr = createTradeAmountTooSmallErr;
